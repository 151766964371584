import React, { Fragment, useEffect, useState } from "react";
import "src/index.css";
import "moment-timezone";
import moment from "moment/moment";
import AuthService from "src/service/api";
import { useNavigate } from "react-router-dom";
import PaymentIcon from "src/images/payments.png";
import { capitalizedWord } from "src/helper/global";
import Header from "src/components/Header/Header";
import OrderHeader from "src/components/Order/OrderHeader";
import OrderProduct from "src/components/Order/OrderProduct";
import OrderCharges from "src/components/Order/OrderCharges";
import { groupByCategory } from "src/helper/groupByCategory";
import OrderDetails from "src/components/Order/OrderDetails/OrderDetails";
import CustomLoader from "src/components/Preloader/Preloader";

export const Order = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [timeZoneId, setTimeZoneId] = useState("America/Los_Angeles");
  const [marketplaceConfig, setMarketplaceConfig] = useState({
    logo: '',
    name: '',
    height: '',
    width: ''
  });
  const productsData = groupByCategory(orderData?.products);

  useEffect(() => {
    let parsedParams = (url) => {
      return url.split("/");
    };

    const params = parsedParams(window.location.pathname);
    const businessId = params[1];
    const orderId = params[2];

    if (!businessId || !orderId) {
      navigate("/404");
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await AuthService.getOrderData(businessId, orderId);
        if (response?.data?.data?.business) {
          document.title = `Orders co | ${response.data.data.business[0].companyName}`;
        } else {
          document.title = `Orders co`;
        }
        if (response) {
          setMarketplaceConfig({
            name: response.data?.data?.orderMetaData?.marketplaceName,
            logo: response.data?.data?.orderMetaData?.marketplaceLogo,
            height: response.data?.data?.orderMetaData?.marketplaceLogoHeight || '37px',
            width: response.data?.data?.orderMetaData?.marketplaceLogoWidth || '152px',
          })
          setOrderData(response.data.data || {});
          setTimeZoneId(
            response?.data?.data?.business[0]?.timezone?.timeZoneId
          );
          setTimeout(() => {
            setLoading(false);
          },1500)
        }
      } catch (e) {
        document.title = `Orders co`;
        setOrderData({});
        setTimeZoneId("America/Los_Angeles");
        setTimeout(() => {
          setLoading(false);
        },1500)
      }
    };
    fetchData().then();
  }, []);

  const convertPayment = (method) => {
    const PAYMENTS = {
      google_pay: "Google Pay",
      apple_pay: "Apple Pay",
      cash: "Cash",
      card: "Card",
      credit: "External Card",
      reward: "Reward",
      coupon: "Coupon",
      link: "Link",
    };
    return PAYMENTS[method];
  };

  if (loading) {
    return  <CustomLoader />
  }

  return (
    <>
      <Header marketplaceConfig={marketplaceConfig}  />
      <main>
        {orderData && Object.keys(orderData)?.length > 0 && (
          <div id="receipt">
            <OrderHeader orderData={orderData} marketplaceConfig={marketplaceConfig} />
            <OrderDetails orderData={orderData} timeZoneId={timeZoneId} />
            <table id="table">
              <tbody>
              <tr>
                <td className="items">Items:</td>
              </tr>
              {Object.keys(productsData)?.length > 0 ? (
                Object.keys(productsData)?.map((keys, index) => {
                  return (
                    <>
                      <Fragment key={`${keys}_${index}`}>
                        {productsData[keys]?.categoryName ? (
                          <>
                            {index !== 0 ? (
                              <div
                                style={{
                                  marginBottom: "16px",
                                  marginTop: "16px",
                                  borderTop: "1px solid rgba(0, 0, 0, 0.4)",
                                }}
                              ></div>
                            ) : (
                              <></>
                            )}
                            <tr
                              style={{
                                marginBottom: "3px",
                              }}
                            >
                              <td
                                style={{
                                  marginLeft: "29px",
                                }}
                                className="product product-name-longer"
                              >
                                {productsData[
                                  keys
                                  ]?.categoryName?.toUpperCase()}
                              </td>
                              <td
                                className="product  product-longer-price"
                                style={{
                                  width: "100%",
                                }}
                              ></td>
                            </tr>
                          </>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                      {productsData[keys]?.items?.map((product, index) => {
                        return (
                          <Fragment key={`${product?._id}_${index}`}>
                            <OrderProduct product={product} />
                          </Fragment>
                        );
                      })}
                    </>
                  );
                })
              ) : (
                <></>
              )}
              </tbody>
            </table>
            <hr className="line-header" />
            <table id="table">
              <tbody>
              <OrderCharges orderData={orderData} />
              </tbody>
            </table>
            {orderData?.payment?.payment_method_details ? (
              <>
                <hr className="line-header" />
                <div className="payment-container">
                  <div>
                    <img src={PaymentIcon} alt="payment icon" />
                  </div>
                  <div>
                    {orderData?.payment?.payment_method_details?.card?.wallet
                      ?.type ? (
                      <p className="payment-title">
                        Payments: by{" "}
                        {convertPayment(
                          orderData?.payment?.payment_method_details?.card?.wallet
                            ?.type
                        )}
                      </p>
                    ) : orderData?.payment?.payment_method_details?.card?.last4 ||
                    orderData?.payment?.payment_method_details?.card?.brand ? (
                      <p className="payment-title">
                        Payments: by {convertPayment("card")}
                      </p>
                    ) : (
                      <></>
                    )}
                    {orderData?.payment?.payment_method_details?.card?.brand ? (
                      <p className="cart-number">
                        Card Type:{" "}
                        {`${capitalizedWord(
                          orderData?.payment?.payment_method_details?.card
                            ?.brand || ""
                        )}`}
                      </p>
                    ) : (
                      <></>
                    )}
                    {orderData?.payment?.payment_method_details?.card?.last4 ? (
                      <p className="cart-number">
                        Card no:{" "}
                        {`XXXX XXXX XXXX ${orderData?.payment?.payment_method_details?.card?.last4}`}
                      </p>
                    ) : (
                      <></>
                    )}
                    <p className="cart-number-title">
                      Date:{" "}
                      {moment(orderData?.formattedCreatedAt).format(
                        "hh:mm A - ddd, MMM Do"
                      )}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        )}
      </main>
    </>
  );
};
