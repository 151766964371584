import { memo } from "react";
const OrderHeader = ({ orderData, marketplaceConfig }) => {
  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        {marketplaceConfig?.logo && (
          <img
            style={{
              width: marketplaceConfig?.width,
              height: marketplaceConfig?.height,
              paddingBottom: '24px'
          }}
            src={marketplaceConfig?.logo} alt="orders.co" />
        )}
        <p className="companyName">
          {orderData?.business[0]?.title || orderData?.business[0]?.companyName}
        </p>
        {marketplaceConfig?.name && (
          <p className="order-number">
            {` by ${marketplaceConfig?.name}`}
          </p>
        )}
        <hr className="line-header" />
        <p className="user-message">
          {`Thanks for ordering, ${orderData?.contactInfo?.firstName || orderData?.contactInfo?.name}!`}
        </p>
        <p className="order-number">
          {`Order #${orderData.number}`}
        </p>
        <hr className="line-header-long" />
      </div>
    </div>
  );
};
export default memo(OrderHeader);
